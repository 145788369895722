import React, {useEffect, useState, useRef} from 'react';
import ShowTyping from "../components/ShowTyping"; 

import { observer } from 'mobx-react-lite';
import { store } from '../stores/AtomicStore';


const CLV: React.FC = () => (
    <div className="App-content">
      <h2>Customer Lifetime Value</h2>
      <h3>Retention Strategies: <a href="https://app.eisengard.ai/shared_canvas_module.html?remote_moduleid=sMYvx%2F00QtCOTlcPIw3Wmw">see details</a></h3>
      <iframe 
        width="52%"
        height="550px"
        frameBorder={"0"}
        allowFullScreen={true}
        src="https://app.eisengard.ai/shared_canvas_module.html?remote_moduleid=Y9aLusfeTNKkL97a%2BA7pPQ">        
      </iframe>
      </div>
);

export default CLV;