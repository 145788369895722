export default function CursorSVG() {
    return (
      <svg
        viewBox="8 4 8 16"
        xmlns="http://www.w3.org/2000/svg"
        className="cursor"
      >
        <rect x="10" y="2" width="4" height="20" />
      </svg>
    );
  }