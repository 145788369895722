import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom'; 
import DemoBot from './Demobot';
import Login from './Login';

const App: React.FC = () => (
  <Router>
     <Routes>
      <Route path="*" element={<Login />} />
      <Route path="demobot" element={<DemoBot />} />
    </Routes>
  </Router>
);

export default App;
