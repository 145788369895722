import React, {useEffect, useState} from 'react';
import ShowTyping from "./components/ShowTyping"; 
import './Demobot.css';

/* Multiple pages */
import Challenger from './pages/Challenger';
import CLV from './pages/CLV';
import Brands from './pages/Brands';
import Atomic from './pages/Atomic';

/* MobX for state management */
import { observer } from 'mobx-react-lite';
import { store } from './stores/Store';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom'; 

/* Book icon */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faHouse, faUser, faCommentsDollar, faSeedling, faAtom, faSquarePlus } from '@fortawesome/free-solid-svg-icons';

import ExampleComponent from './components/ExampleComponent';

import useExternalApiService from './components/ExternalApiService';

const Home: React.FC = observer(() => {
  
    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        store.setUserInput(event.target.value);
    };
  
    /* Slide out Frame conditionally */
    /* Moved over to mobx in iframeStore */
    const [isIframeVisible, setIsIframeVisible] = useState(false);
    const [animationClass, setAnimationClass] = useState('');
    const [isIframeRendered, setIsIframeRendered] = useState(false);
    const [chatClass, setChatClass] = useState('');
    const [glassClass, setGlassClass] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
  
    const handleToggleIframe = () => {
      setIsIframeVisible(!isIframeVisible);
    };
  
    const closeIframe = () => {
      setIsIframeVisible(false);
      navigate(-1);  // Go back to the previous page.
    };
  
    useEffect(() => {  
      if(location.hash.startsWith('#expansion')) {
        handleToggleIframe();
      }
    }, [location]);
  
    useEffect(() => {
      // When the input changes, set the animation class to slide in or out based on whether the input is empty or not
     if (isIframeVisible) {
        setIsIframeRendered(true);
        setTimeout(() => {
          setChatClass('move-left');
          setAnimationClass('slide-in');
          setGlassClass('with-shadow');
        },50);
     } else {
        setAnimationClass('slide-out');
        setChatClass('');
        setGlassClass('');
        setTimeout(() => {
          setIsIframeRendered(false);
        },500);
     }
    }, [isIframeVisible]);
    
  
    // Preprocess the markdown to replace [button:...] with <Button text="..."/>
    const preprocessMarkdownToButton = (markdown: string) => {
      return markdown.replace(/\[button:(.*?)\]/g, '[$1](#button:$1)');
    };
  
    const handleSubmit = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => { 
      if (event.key === 'Enter' && store.userInput.trim()) {
        //console.log(store.userInput);
        const userInput = store.userInput;
        store.setUserInput(''); // Clear input field after submitting      
        store.addPrompt(userInput);
        
        if (userInput === "I'm going to Albany next week, who should I talk to?" || userInput === "asd") {
          const bakedMarkdwon = preprocessMarkdownToButton(`Of course, these are the best five people you should talk to: \n
  |   |    |   |   |
  |:---|:---:|:---:|:---:|
  | 1. Jamie Goodman | [button:Expansion] | [button:Prob:95%] | $91k |
  | 2. Tony Jorgensen, MD | [button:Retention] | [button:Prob:93%] | $85k|
  | 3. Natasha Kristoff | [button:Expansion] | [button:Prob:89%] | $95k |
  | 4. Jeff Gundersen  | [button:Acquisition] |  [button:Prob:85%] | $61k |
  | 5. Jay Gupta MD | [button:Expansion] | [button:Prob:83%] | $84k |
          `)        
          store.addAnswer(bakedMarkdwon);
        } else if (userInput === "Expansion") {
          handleToggleIframe()        
        } else {
          const ollamaResponse = await store.sendMessageToFlask(userInput);    
  
          // For some reason the text response from ollamaResponse is escaping \n's by adding an extra "\".  Let's properly escape it for rednering in markdown.
          let mkdwnText = ollamaResponse.text.replace(/\\n/g, '\n')
  
          //console.log("msg=", ollamaResponse.response);
          store.addAnswer(ollamaResponse.response);
          store.setText(mkdwnText);
          //console.log("txt=", mkdwnText);
          store.setPic(ollamaResponse.pic);
          store.setVid(ollamaResponse.vid);
        }
      }   
    };

    const apiUrl = process.env.REACT_APP_EAI_URL;
    console.log("Using server address from Demobot: ", apiUrl);
    const { postData } = useExternalApiService(apiUrl);

    const callEaiApi = async () => {

      let json_param = {
        data_table_name:'admin_mastertable',
        user: "admin",
      }

      const result = await postData('/api/get_data_table_idx', json_param);
      console.log("Finished calling postData at EAI endpoint", result);      
      console.log("result=", result);

    };
  
    return (
      <main className="App-content">        
        <div className="main-container">
          <div className={`chat-container ${chatClass}`}>
            <div className="prompt">
                {store.prompts[store.prompts.length -1 ]}
              </div>        
              <div className="glass">        
                { <ShowTyping 
                    msg={ store.answers[store.answers.length -1 ] }
                    txt={ store.textDisplay }
                    pic={ store.picURL }
                    vid={ store.vidURL }                  
                /> }                           
              </div>        
              <p>
                <textarea 
                    className="slick-prompt"                    
                    placeholder="Remind me of my routine"              
                    value={store.userInput}
                    onChange={handleInputChange}
                    onKeyDown={handleSubmit} // This triggers when we submit or Enter              
                    autoFocus
                />
              </p>
              <button onClick={callEaiApi}>Click me to get test EAI API</button>              
          </div>
          {isIframeRendered && (        
            <div className={`iframe-container ${animationClass}`}>
              <button className="close-button" onClick={closeIframe}>×</button>
              <iframe 
                  style={{ width: '100%', height: '100%', border: 'none' }}
                  allowFullScreen={true}
                  src="https://app.eisengard.ai/shared_canvas_module.html?remote_moduleid=Fy5nPC5cRpeLmr8SIp%2F%2FeA">        
            </iframe>
            </div>
          )}
        </div>    
      </main>
    );
  });
  

  const Demobot: React.FC = observer(() => {
    const [backgroundImage, setBackgroundImage] = useState<string>('url("https://images.unsplash.com/photo-1490682143684-14369e18dce8?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")');
    const location = useLocation();
    const [activePeriod, setActivePeriod] = useState<string>('Day');
    const [theme, setTheme] = useState<string>('dark'); // Add theme state: either light or dark

    /* Moving images up here to be defined */
    /* Backgrounds that needs dark theme settings */
    const anoopBkImg : string = "https://images.unsplash.com/photo-1490682143684-14369e18dce8?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
    const jeffBkImg : string = "https://images.unsplash.com/photo-1439405326854-014607f694d7?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";    
    const desertBkImg: string = "https://images.unsplash.com/photo-1541789094913-f3809a8f3ba5?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

    /* Backgrounds that need light theme settings */
    const cleeBkImg : string = "https://images.unsplash.com/photo-1518655048521-f130df041f66?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

    useEffect(() => {    
        if (location.pathname === '/') {    
            setBackgroundImage(anoopBkImg);            
            setTheme("dark");
        } else if (location.pathname === '/clv') {      
            setBackgroundImage("/CLV.jpg");
            setTheme("light");
        } else if (location.pathname === '/challenger') {
            setBackgroundImage("/challenger.jpg");
            setTheme("light");
        } else if (location.pathname === '/brands') {
            setBackgroundImage("/brands.jpg");
            setTheme("light");
        } else if (location.pathname === '/atomic') {
            setBackgroundImage("/atomic.jpg");
            setTheme("light");
        } else {
            setBackgroundImage(anoopBkImg);
            setTheme("dark");
        }

    }, [location]);
    
    const handlePeriodClick = (period: string) => {
        setActivePeriod(period);
        if (period === 'Day') {
        store.setAnswers(`## Good morning, Clarence \n This is what your schedule looks like: \n * 9am: Breakfast with [Dr. Terry Smith](https://riversidehealth.org/) \n * 10:45am: Dr. Jennifer Snyder @ Montefiore \n * 12pm: Lunch with Dr. Tom Burns @ [Weill Cornell](https://maps.app.goo.gl/ZcTLCgXjuCHjECNS7) \n`); 
        } else if (period === 'Week') {
        store.setAnswers(`Here are a few of your top priorities this week \n you want me to remind you of. \n 1. Close Dr. Burns \n 2. Cold-call 8 more new contacts \n 3. Prep for route with my sales director.`);
        } else if (period === 'Month') {
            store.setAnswers(`In the previous month, you've discussed the following issues with your sales director: 
            \n 1. [Be ready to show her your coverage in territory](https://app.eisengard.ai/shared_canvas_module.html?remote_moduleid=rE%2BtL8QnQ5ad%2BU5g8RAfKA)
            \n 2. Discuss up-coming issues and problems.
            \n 3. Start applying principles from The Challenger Sale."
            `);
        } else if (period === 'Quarter') {
        store.setAnswers(`Your next QBR is scheduled on 7/8/24.  \n Want me to remind you of your progress on that?`);
        } else {
        store.setAnswers(`Default answer for ${period}`); // Set the default answer based on the period
        }

        
    };

    const renderTopCenterContent = () => {
        /*if (location.pathname === '/') {
        return 'Day | Week | Month | Quarter';
        } else if (location.pathname === '/clv') {
        return 'Acquisition | Retention | Expansion';
        }
        return '';
        */

        if (location.pathname === '/') {
        const periods = ['Day', 'Week', 'Month', 'Quarter'];
        return periods.map((period, index) => (
            <React.Fragment key={period}>
            <a
                key={period}
                className={activePeriod === period ? 'active' : ''}
                onClick={() => handlePeriodClick(period)}
                href="#"
            >
                {period}
            </a>
            {index < periods.length - 1 && ' | '}
            </React.Fragment>
        ));
        } else if (location.pathname === '/clv') {
        return 'Acquisition | Retention | Expansion';
        }
        return '';
    };

    return (    
    <div className={`App ${theme}`} style={{ backgroundImage: `url(${backgroundImage})` }}> 
      <header className="App-header">
        <div className="progress top-left">
          $980k
          <br />
          <span>Current Progress</span>
        </div>          
        <div className="progress top-center">
          {renderTopCenterContent()}
        </div>
        <div className="progress top-right">$1.50M<br /><span>Year End Goal</span></div>
        <div className="dropdown">
          <button className='dropbtn'>
            <FontAwesomeIcon icon={faBook} />
          </button>
          <div className="dropdown-content">
            <Link to="/"><FontAwesomeIcon icon={faHouse} />&thinsp; &thinsp; &thinsp; Our Company Way</Link>            
            <Link to="/clv"><FontAwesomeIcon icon={faUser} />&thinsp; &thinsp; &thinsp; The CLV Framework</Link>
            <Link to="/challenger"><FontAwesomeIcon icon={faCommentsDollar} />&thinsp; &thinsp; The Challenger Sale</Link>          
            <Link to="/brands"><FontAwesomeIcon icon={faSeedling} />&thinsp; &thinsp; &thinsp; How Brands Grow</Link>
            <Link to="/atomic"><FontAwesomeIcon icon={faAtom} />&thinsp; &thinsp; &thinsp; Atomic Habits</Link>
            <Link to="/custom"><FontAwesomeIcon icon={faSquarePlus} />&thinsp; &thinsp; &thinsp; Add Your Own</Link>             
          </div>
        </div>
      </header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/challenger" element={<Challenger />} />
        <Route path="/clv" element={<CLV />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/atomic" element={<Atomic />} />
      </Routes>
    </div>
    );
});
  
export default Demobot;