import React, {useEffect, useState } from 'react';
import LoginButton from './components/LoginButton';
import LogoutButton from './components/LogoutButton';

import { useAuth0 } from "@auth0/auth0-react";

const Login: React.FC = () => {    
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [userMetadata, setUserMetadata] = useState(null);    

    useEffect(() => {
        const getUserMetadata = async () => {
          const domain = "dev-r3pn41if.us.auth0.com";
      
          try {
            const accessToken = await getAccessTokenSilently({
              authorizationParams: {
                audience: `https://${domain}/api/v2/`,
                scope: "read:current_user",
              },
            });
      
            const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user?.sub}`;
      
            const metadataResponse = await fetch(userDetailsByIdUrl, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
      
            const { user_metadata } = await metadataResponse.json();
      
            setUserMetadata(user_metadata);
          } catch (e) {
            console.log(e);
          }
        };
      
        getUserMetadata();
    }, [getAccessTokenSilently, user?.sub]);

    return(        
        isAuthenticated ? (                    
            <div>
            <h1>Welcome {user?.name}!</h1>
            <p>You're logged in!</p>
            <img src={user?.picture} alt={user?.name} />
            <h2>{user?.name}</h2>
            <p>{user?.email}</p>                
            <h3>User Metadata</h3>
            {userMetadata ? (
                <pre>{JSON.stringify(userMetadata, null, 2)}</pre>
            ) : (
                "No user metadata defined"
            )}
            <p>
            <LogoutButton />
            </p>
            </div>                                        
        ) : (
            <div>
            You're logged out, please log back in. 
            <LoginButton />
            </div>
            
        )
    );    
};
  
export default Login;
  

