// ExternalApiService.js

/*
import { useAuth0 } from '@auth0/auth0-react';

class ExternalApiService {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.auth0 = useAuth0();
  }

  async getAccessToken() {
    try {
      const token = await this.auth0.getAccessTokenSilently();
      return token;
    } catch (error) {
      console.error('Error getting access token:', error);
      throw error;
    }
  }

  async getData(endpoint) {
    try {
      const token = await this.getAccessToken();
      const response = await fetch(`${this.baseURL}${endpoint}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  async postData(endpoint, data) {
    try {
      const token = await this.getAccessToken();
      const response = await fetch(`${this.baseURL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw new Error(`Error posting data: ${response.statusText}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  }
}

export default ExternalApiService;
*/

import { useAuth0 } from '@auth0/auth0-react';

const useExternalApiService = (baseURL) => {
  const { getAccessTokenSilently } = useAuth0();

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      console.log('Access Token procured = ', token);
      return token;
    } catch (error) {
      console.error('Error getting access token:', error);
      throw error;
    }
  };

  const getData = async (endpoint) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${baseURL}${endpoint}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  const postData = async (endpoint, data) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${baseURL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw new Error(`Error posting data: ${response.statusText}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  };

  return { getData, postData };
};

export default useExternalApiService;