import React, {useEffect, useState, useRef} from 'react';
import ShowTyping from "../components/ShowTyping"; 

import { observer } from 'mobx-react-lite';
import { store } from '../stores/AtomicStore';

const Atomic: React.FC = observer(() => {
    const inputRef = useRef<HTMLInputElement>(null);
  
    useEffect(() => {
      // Programmatically focus the input field when the component mounts
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);
  
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        store.setUserInput(event.target.value);
    };
  
    const handleSubmit = async (event: React.KeyboardEvent<HTMLInputElement>) => { 
      if (event.key === 'Enter' && store.userInput.trim()) {
        console.log(store.userInput);
        const userInput = store.userInput;
        store.setUserInput(''); // Clear input field after submitting      
  
        store.addPrompt(userInput);
        
        const ollamaResponse = await store.sendMessageToFlask(userInput);    
  
        console.log("msg=", ollamaResponse.response);
        store.addAnswer(ollamaResponse.response);
        store.setText(ollamaResponse.text);
        console.log("txt=", ollamaResponse.text);
        store.setPic(ollamaResponse.pic);
        store.setVid(ollamaResponse.vid);
      }   
    };
  
    return (
      <div className="App-content">
        <div className="prompt">
          {store.prompts[store.prompts.length -1 ]}
        </div>        
        <div className="glass">        
          { <ShowTyping 
              msg={ store.answers[store.answers.length -1 ] }
              txt={ store.textDisplay }
              pic={ store.picURL }
              vid={ store.vidURL }
          /> }                           
        </div>        
        <p>
          <input className="slick-prompt"
              type="text"
              placeholder="How do I use this?"              
              value={store.userInput}
              onChange={handleInputChange}
              onKeyDown={handleSubmit} // This triggers when we submit or Enter              
              ref={inputRef}  // Attach the ref to the input to autoFocus programmatically.
          />
        </p>
      </div>
    );
  
});

export default Atomic;