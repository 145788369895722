import React, { useState, useEffect } from 'react';
import CursorSVG from './CursorSVG';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface ShowTypingProps {
  msg: string;
  txt: string;
  pic: string;
  vid: string;
}

const ShowTyping: React.FC<ShowTypingProps> = ({ msg , txt, pic, vid}) => {  
  const [displayResponse, setDisplayResponse] = useState("");
  const [completedTyping, setCompletedTyping] = useState(false);

  useEffect(() => {
    setCompletedTyping(false);

    let i = 0;
    /*const stringResponse = msg ;*/
    const stringResponse = msg + txt;
    
    const intervalId = setInterval(() => {
      setDisplayResponse(stringResponse.slice(0, i));
      i++;

      if (i > stringResponse.length) {
        clearInterval(intervalId);
        setCompletedTyping(true);        
      }
    }, 35);
    
    //console.log(stringResponse);
    
    return () => clearInterval(intervalId);
  }, [msg]);
  
  // Custom Link component styled as a button
  type LinkProps = {
    href: string;
    children: React.ReactNode;
  };

  const CustomLink: React.FC<LinkProps> = ({ href, children }) => {    
    //console.log("href:", href);
    if (href.startsWith('#button:Expansion')) {      
      const actualHref = href.replace('#button:Expansion', '#expansion')
      return <a className="custom-button-expansion" href={actualHref}>{children}</a>;
    } else if(href.startsWith('#button:Retention')) {
      const actualHref = href.replace('#button:Retention', '#expansion')
      return <a className="custom-button-retention" href={actualHref}>{children}</a>;
    } else if(href.startsWith('#button:Acquisition')) {
      const actualHref = href.replace('#button:Acquisition', '#expansion')
      return <a className="custom-button-acquisition" href={actualHref}>{children}</a>;
    } else if(href.startsWith('#button:Prob:')) {
      const actualHref = href.replace('#button:Prob:', '')
      return <a className="custom-button-prob" href={actualHref}>{children}</a>;
    } else { 
      //console.log("triggering default case");
      return <a href={href}>{children}</a>;
    }
  };

  const components = {
    h2: ({node, ...props}: any) => <h2 className="center-align" {...props} />,
    h3: ({node, ...props}: any) => <h3 className="center-align" {...props} />,
    a: ({ node, ...props}: any) => <CustomLink {...props} />,
  };

  return (    
    <>      
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={/*{
          h2: ({node, ...props}) => <h2 className="center-align" {...props} />,
          h3: ({node, ...props}) => <h3 className="center-align" {...props} />,
        }*/
        components
       }
      >
        {displayResponse}
      </ReactMarkdown>
      {displayResponse === "" && <CursorSVG />}
      {!completedTyping && <CursorSVG />}
      
      {/* Conditional rendering based on txt, pic, and vid props */}    
      {/*completedTyping && txt && <p>{txt}</p>*/}
      {completedTyping && pic && (pic !== "nan") && <p><img src={pic} alt="This is a picture!" /></p>}
      {completedTyping && vid && (vid !== "nan") && (
        <p>
          <video src={vid} controls>
          Your browser does not support the video tag.
          </video>
        </p>
      )}
    </>
  );

};

export default ShowTyping;