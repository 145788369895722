import { makeAutoObservable } from "mobx";
//import { useAuth0 } from "@auth0/auth0-react";
import { access } from "fs";

interface OllamaApiResponse {
    model: string;
    created_at: string;
    response: string;
    text: string;
    vid: string;
    pic: string;
    done: boolean;
}

interface EAIApiResponse {
  success: boolean;
  error_msg: string;
  result: number;
}

class Store {
    //domain = "dev-r3pn41if.us.auth0.com";              

    //private getAccessTokenSilently: () => Promise<string> = () => useAuth0().getAccessTokenSilently();

    userInput = "";
    prompts: string[] = [""];
    answers: string[] = [
        '## Good morning, Clarence \n This is what your schedule looks like: \n * 9am: Breakfast with [Dr. Terry Smith](https://riversidehealth.org/) \n * 10:45am: Dr. Jennifer Snyder @ Montefiore \n * 12pm: Lunch with Dr. Tom Burns @ [Weill Cornell](https://maps.app.goo.gl/ZcTLCgXjuCHjECNS7) \n'
    ];
    textDisplay = "";
    picURL = "";
    vidURL = "";    

    constructor() {
        makeAutoObservable(this);           
        
    }    
    
    setUserInput(value: string) {
        this.userInput = value;
    }
    
    addPrompt(prompt: string) {
        this.prompts.push(prompt);
    }

    setAnswers(newAnswer: string) {
      this.answers = [newAnswer];
    }
    
    addAnswer(answer: string) {
    this.answers.push(answer);
    }

    setText(text: string) {
    this.textDisplay = text;
    }

    setPic(pic: string) {
    this.picURL = pic;
    }

    setVid(vid: string) {
    this.vidURL = vid;
    }

    async sendMessageToFlask(msg: string): Promise<OllamaApiResponse> {
        const requestBody = {
          prompt: msg
        };
    
        const apiUrl = process.env.REACT_APP_API_URL;
        console.log('API URL:', apiUrl);
    
        try {
          const response = await fetch(`${apiUrl}/langgrapho_gen`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody)
          });
    
          if (response.ok) {
            const result: OllamaApiResponse = await response.json();
            //console.log('Server response:', result);
            return result;
          } else {
            //console.error('Server error:', response.status, response.statusText);
            return {
              model: '',
              created_at: '',
              response: 'Error occurred',
              text: '',
              vid: 'nan',
              pic: 'nan',
              done: false,
            };
          }
        } catch (error) {
          //console.error('Network error:', error);
          return {
            model: '',
            created_at: '',
            response: "I'm not connected to the internet -- I can't answer your question at this point...",
            text: '',
            vid: '',
            pic: '',
            done: false,
          };
        }
    }
    
    async test_eai_call(): Promise<EAIApiResponse> {      
      const apiUrl = process.env.REACT_APP_EAI_URL;
      console.log('Calling EAI Server URL:', apiUrl);
      //console.log('access_token=', this.accessToken);
  
      const requestBody = {
        data_table_name:'admin_mastertable',
        user: "admin",
      };
  
      const response = await fetch(`${apiUrl}/api/get_data_table_idx`, {
        method: 'POST',
        /*headers: {          
          'Authorization': `Bearer ${this.accessToken}`,
          'Content-Type': 'application/json',
        },*/
        body: JSON.stringify(requestBody)
      });
  
      if (response.ok) {
        console.log('Server response:', await response.json());
        return await response.json();
      } else {
        console.error('Server error:', response.status, response.statusText);
        return await response.json();
       }
    }


}

export const store = new Store();